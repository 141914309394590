import React from "react";
import * as styles from "./MagazineCeo.module.scss";
import Logo from "../../../svg/mwlogo.svg";
function MagazineCeo(){
    return(
        <div className={styles.container}>

            <div className={styles.image}>
            </div>
            <div className={styles.content}>
                <Logo />
                <h1>Editorial Note </h1>
                <p>As we stand on the brink of a new era in the way we communicate and do our work, there is a world of opportunity for us to explore. From creating innovative software solutions to offering practical advice, we are passionate about creating a virtual presence that is both efficient and enjoyable. <br/><br/> Come with us as we embark on a journey to push the boundaries of communication and seek out the ultimate workspace experience. Together, we can define a new way of working and living.</p>
                <br/><br/>
                <p className={styles.ceotext}>CEO and Creative Director,<br/> Jone Smedsvig </p>
            </div>
        </div>
    );
}
export default MagazineCeo;