import React from "react";
import * as styles from "./CoverMagazine.module.scss";
import image from "../../../images/wizybig2.png";

import image1 from "../../../images/wizymagazine7.png";
import image2 from "../../../images/wizymagazine2.png";
import image4 from "../../../images/wizymagazine4.png";

import image5 from "../../../images/wizymagazine8.png";


function CoverMagazine(){
    return(
        <div className={styles.mainContainer}>
   <div className={styles.container}>
           <img src={image}
           alt="cover"
            />
           <div className={styles.image} />
            <div className={styles.content}>
                <h1>Innovating 2023
                </h1>
                <p>COVID-19 has presented us with a new challenge: digital fatigue. Our Magazine dives into how communication can help us transition back to normal. </p>
            </div>
        </div>
        <div className={styles.articles}>
        <img src={image1} alt="article"/>
        <img src={image2} alt="article"/>
        <img className={styles.image3} src={image5}  alt="article"/>
        <img src={image4} alt="article" />
        </div>
        </div>
    );
}
export default CoverMagazine;