// extracted by mini-css-extract-plugin
export var articles = "LatestArticles-module--articles--373d7";
export var bd = "LatestArticles-module--bd--c5a3f";
export var bottom = "LatestArticles-module--bottom--d5178";
export var bottomCards = "LatestArticles-module--bottomCards--2eb82";
export var card = "LatestArticles-module--card--77963";
export var content = "LatestArticles-module--content--9a31f";
export var data = "LatestArticles-module--data--37f87";
export var image = "LatestArticles-module--image--6a77e";
export var inputgroup = "LatestArticles-module--inputgroup--45130";
export var leftSection = "LatestArticles-module--leftSection--00f93";
export var maincard = "LatestArticles-module--maincard--5a45f";
export var rightSection = "LatestArticles-module--rightSection--c7a6a";
export var rightcard = "LatestArticles-module--rightcard--9d28a";
export var title = "LatestArticles-module--title--a898e";
export var top = "LatestArticles-module--top--c3f66";