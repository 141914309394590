import * as styles from "./MagazineImage.module.scss";
import React from "react";
import { Link } from "gatsby";
function MagazineImage({image,date,title,description,extraClass,link}){
    return(
       <Link to={link}   className={`${styles.container} ${styles[extraClass]}`}>
        <div className={styles.image} style={{backgroundImage:`url(${image})`}}/>
        <div className={styles.content}>
            <h3>{date}</h3>
            <h1>{title}</h1>
            <p>{description}</p>
            {/* <button>Read More</button> */}
        </div>
       </Link>
    );
}
export default MagazineImage;