import React, { useState } from "react";
import * as styles from "./GetwizrSection.module.scss";
import logo from "../../../images/getwizrlogo.png";
import {BsFillPlayFill} from "react-icons/bs";
function GetwizrSection(){
    const[state,setState]=useState(false);
    return(
        <div className={styles.container}>

            <div className={styles.image}>
           {state ?  <video onLoad={false} autoPlay={state?true:false} controls={true} >
            <source onLoad={""}  src={"https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674051818/mwvideos/animationoffice2_zwgu1q.mp4"}  /> </video>:
             <div className={styles.icon}><BsFillPlayFill onClick={()=>setState(true)}/> </div>}
            </div>
            <div className={styles.content}>
                <img src={logo} alt="logo"/>
                <h1>See what comes next…</h1>
                <p>We are building the ”The Future of Work” and it all starts with ideas, concepts and inspirations. We aim to share and collaborate as we pursue creating the best tools for communicating what matters, to express yourself.  </p>
            </div>
        </div>
    );
}
export default GetwizrSection;