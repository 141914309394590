import React from "react";
import WhiteCard from "../../molecules/WhiteCard/WhiteCard";
import * as styles from "./OldArticles.module.scss";

function OldArticles({data}){
    return(
        <div className={styles.container}>
            <h1>Popular 2020-2021</h1>
            <div className={styles.data}>
              
            {data.edges.filter((post) => post?.node?.date < "2022-08-01" && post?.node?.popup?.popupContent===null).slice(0,9).map((post) =>{
                return(
                    <>
                    <WhiteCard desc={post?.node?.shortDescription?.shortText} image={post?.node?.articlesD?.bannerImage?.sourceUrl} title={post?.node?.title} link={`/blog/${post?.node?.slug}`}/>
                    </>
                )
            } )}
            </div>
        </div>
    );
}
export default OldArticles;