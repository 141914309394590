import { Link } from 'gatsby';
import React from 'react';
import * as style from "./style.module.scss";

export default function ArticlesSliderItem({index,description,description2,date,date2 , bgImage1, bgImage2 , extraClass, link, link2}) {
  return <>
   <div index={index} className={`${style.sliderContent} ${style[extraClass]}`} >
        <div className={style.container}>
            {/* <h1 className={style.title}>Featured Stories</h1> */}
            <div className={style.wrapper}>
          <Link to={link}>
            <div className={style.content} style = {{backgroundImage:`url(${bgImage1})`}}>
                {/* <div className={style.contentHolder}>
                </div> */}
                <p className={style.desc}>
                    {/* <span>{date}</span> */}
                   {description}
                </p>
        
            </div>
            </Link>
            <Link to={link2}>
            <div className={style.content} style = {{backgroundImage:`url(${bgImage2})`}}>
                <p className={style.desc}>
                    {/* <span>{date2}</span> */}
                   {description2}
                </p>
                </div>
            </Link>
            </div>
        </div>
    </div>
  </>;
}
