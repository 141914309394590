import React from "react";
import * as styles from "./VideoBanner.module.scss";
import image from "../../../images/videoconnect1.png";
import {BsFillPlayFill} from "react-icons/bs";
import {FaFacebookF} from "react-icons/fa";
function VideoBanner(){
    return(
      <div className={styles.container}>
        <div className={styles.banner}>
           <a aria-label="redirect" href="https://www.facebook.com/masterwizr/videos/572728950968235" target={"_blank"} rel="noreferrer">

          <div className={styles.image}>
            <img src={image} alt="vdbanner"/>
            <BsFillPlayFill />
          </div>
           </a>
          <div className={styles.content}>
            <h2>Follow us on Facebook</h2>
            <p><b style={{opacity:"0.8"}}>With 145k+ followers and 2M video views, we regularly share fresh content for education and entertainment.</b> <br/> <br/> More is yet to come!</p>
           <div className={styles.link}>
           <a aria-label="redirect" target={"_blank"} href="https://www.facebook.com/masterwizr/" rel="noreferrer">Check us out</a>
           <FaFacebookF /> 
           </div>
          </div>
        </div>
      </div>
    );
}
export default VideoBanner;