import React, { useState } from "react";
import { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import ArticlesSlider from "../components/molecules/Magazine/articlesSlider/index";
import Head from "../components/ogranisms/head";
import FreeTrial from "../components/atoms/freeTrial";
import InstaSlider from "../components/molecules/Magazine/instaSlider";
import AOS from "aos";
import "aos/dist/aos.css";
import MagazineSections from "../components/ogranisms/MagazineSections/MagazineSections";
import Quotes from "../components/molecules/Quotes/Quotes";
import Modal from "../components/molecules/MagazineSubscribeModal/Modal";
import VideoBanner from "../components/molecules/VideoBanner/VideoBanner";

import * as styles from "./styles.module.scss";
import MagazineNavigation from "../components/molecules/MagazineNavigation/MagazineNavigation";
import Advertisement from "../components/molecules/Advertisement/Adevertisement";
import MagazineFirstSection from "../components/molecules/MagazineFirstSection/MagazineFirstSection";
import LatestArticles from "../components/molecules/LatestArticles/LatestArticles";
import WeekPicks from "../components/ogranisms/WeekPicks/WeekPicks";
import LatestStories from "../components/ogranisms/LatestStories/LatestStories";
import GetwizrSection from "../components/ogranisms/GetwizrSection/GetwizrSection";
import MainQuote from "../components/molecules/MainQuote/MainQuote";
import CoverMagazine from "../components/molecules/CoverMagazine/CoverMagazine";
import { graphql, useStaticQuery } from "gatsby";
import OldArticles from "../components/ogranisms/OldArticles/OldArticles";
import MagazineCeo from "../components/molecules/MagazineCeo/MagazineCeo";
import Subscribe from "../components/molecules/Subscribe/Subscribe";
import { Helmet } from "react-helmet";
function Modules() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  const [showPopUp, setShowPopUp] = useState(false);
  const showPopupHandler = () => setShowPopUp(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      showPopupHandler();
    }, 40000);
    return () => clearTimeout(timer);
  }, [showPopUp]);
  let popup = null;
  if (showPopUp) {
    popup = <Modal handleclose={() => setShowPopUp(false)} />;
  }

  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          edges {
            node {
              articlesD {
                showItem
                bannerImage {
                  node {
                    sourceUrl
                    description
                    date
                  }
                }
                monthPick
                bannerTitle
                bannerDesc
                presentation
              }
              shortDescription {
                shortDescription
                shortText
                articleCategory
              }
              popup {
                popupContent
              }
              slug
              title
              modified
              date
            }
          }
        }
      }
    `
  );
  const data = [
    {
      quote:
        "“The goal of this presentation is to impress, rather than inform“ ",
      name: "William Rushton ",
    },
    {
      quote:
        "“Whether you think you can, or you think you can’t – you’re right.”",
      name: "Henry Ford",
    },
    {
      quote: "“Luck is a matter of preparation meeting opportunity”",
      name: "Seneca",
    },
    {
      quote: "“Hard work beats talent when talent doesn't work hard”",
      name: "Tim Notke ",
    },
    {
      quote: "“It's not about ideas, it's about making ideas happen”",
      name: "Scott Belsky",
    },
    {
      quote:
        "“The quality of your questions will determine the success of your worklife“",
      name: "Jone Smedsvig",
    },
  ];
  const data2 = [
    {
      quote: "“You can achieve anything you put your mind and hard work to”",
      name: "Sara Sampaio ",
    },
    {
      quote: "“Ambition is enthusiasm with a purpose”",
      name: "Frank Tyger",
    },
    {
      quote: "“Pleasure in the job puts perfection in the work“",
      name: "Aristotle",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Magazine</title>
        <link rel="canonical" href="https://masterwizr.com/magazine" />
      </Helmet>
      <Head
        title="Magazine - MASTER WiZR"
        description="Welcome to the WiZR World , better than real"
        ogTitle="Magazine"
      />
      <main>
        <Header />
        <MagazineNavigation />
        <MagazineFirstSection />
        <CoverMagazine />
        <LatestArticles />
        <div className={styles.bannermagazine}>
          <h1>
            Your way of communicating can unlock potential and create deeper
            connections.{" "}
          </h1>
          <p>WiZR Magazine unravels the essence of communication.</p>
        </div>
        <Advertisement
          url={
            "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1674051865/mwvideos/webvideo4-2_mjthax.mp4"
          }
        />
        <WeekPicks data={allWpPost} />
        <MagazineCeo />
        <div id="quotes">
          <Quotes title="Simplify your work life" data={data} />
        </div>
        <LatestStories data={allWpPost} />
        <MainQuote />
        <Subscribe />
        <MagazineSections data={allWpPost} />
        <GetwizrSection />
        <OldArticles data={allWpPost} />
        <VideoBanner />
        <ArticlesSlider />
        <Quotes title="" data={data2} />
        {/* <InstaSlider /> */}
        <FreeTrial />
        {/* <SubscribeForm /> */}
        <Footer />
      </main>
    </>
  );
}

export default Modules;
