import React from "react";
import * as styles from "./MainQuote.module.scss";
import sign from "../../../images/stevesign.png";
import steve from "../../../images/stevejobs.png";

function MainQuote() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>
          <span>“</span>Innovation distinguishes between a leader and a follower.
        </h1>
        <img src={sign} alt="sign"/>
      </div>
      <img src={steve} alt="steve"/>
    </div>
  );
}
export default MainQuote;
