import React, { useState } from "react";
import * as styles from "./LatestArticles.module.scss";
import SmCard from "../SmCard/SmCard";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FiSearch } from "react-icons/fi";
function LatestArticles() {
  const [searchTerm, setSearchTerm] = useState("");

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          edges {
            node {
              articleTemplate {
                frontArticle
              }
              articlesD {
                showItem
                bannerImage {
                  node {
                    sourceUrl
                    description
                    date
                  }
                }
                presentation
                monthPick
                bannerTitle
              }
              shortDescription {
                articleCategory
              }
              slug
              title
              date
            }
          }
        }
      }
    `
  );
  return (
    <div id="latest-articles" className={styles.articles}>
      <div className={styles.inputgroup}>
        <FiSearch />
        <input
          placeholder="Search"
          type="text"
          id="searchInput"
          onChange={handleSearchChange}
        />
      </div>
      <div className={styles.bd} />
      <div className={styles.image2} />
      <h1 className={styles.title}>Latest Stories</h1>
      <div className={styles.data}>
        <div className={styles.leftSection}>
          {allWpPost.edges
            .filter(
              (post) => post?.node?.title === "Becoming a Master of Anything"
            )
            .slice(0, 1)
            .map((post) => {
              return (
                <Link to={`/blog/${post.node.slug}`}>
                  <div
                    style={{
                      backgroundImage: `url('${post.node.articlesD.bannerImage?.sourceUrl}')`,
                    }}
                    className={styles.maincard}
                  >
                    <div className={styles.content}>
                      <h2>{post?.node?.title}</h2>
                    </div>
                  </div>
                </Link>
              );
            })}
          <div className={styles.bottomCards}>
            {allWpPost.edges
              .filter(
                (post) => post?.node?.articleTemplate?.frontArticle === true
              )
              .slice(0, 2)
              .map((post) => {
                return (
                  <Link to={`/blog/${post.node.slug}`}>
                    <div
                      style={{
                        backgroundImage: `url('${post.node.articlesD.bannerImage?.sourceUrl}')`,
                      }}
                      className={styles.card}
                    >
                      <div className={styles.content}>
                        <h1>{post?.node?.title}</h1>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>

        <div className={styles.rightSection}>
          {allWpPost.edges
            .filter(
              (post) =>
                post?.node?.date >= "2022-12-01" &&
                post?.node?.articlesD?.presentation === null &&
                post?.node?.articleTemplate?.frontArticle === null &&
                post?.node?.articlesD?.monthPick === null &&
                post?.node?.articlesD?.showItem === true &&
                post?.node?.title
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
            .slice(-5)
            .map((post) => {
              return (
                <>
                  <SmCard
                    tag={post?.node?.shortDescription?.articleCategory}
                    image={post.node.articlesD.bannerImage?.sourceUrl}
                    title={post.node.title}
                    link={`/blog/${post.node.slug}`}
                  />
                </>
              );
            })}
        </div>
      </div>
      <div className={styles.bottom} />
    </div>
  );
}
export default LatestArticles;
