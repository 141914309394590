import React from "react";
import * as styles from "./Advertisement.module.scss";
function Advertisement({url}){
    return(
        <div className={styles.advertisement}>
           <video  muted autoPlay loop>
            <source src={url} type="video/mp4" />
            </video>
        </div>
    );
}
export default Advertisement;