import WhiteCard from "../../molecules/WhiteCard/WhiteCard";
import * as styles from "./WeekPicks.module.scss";
import React from "react";
function WeekPicks({data,extraClass}){
    return(
        <div id="month-picks" className={`${styles.container} ${styles[extraClass]}`}>
            <h1>This month's picks</h1>
            <div className={styles.data}>
              
            {data?.edges.filter((post) => post?.node?.articlesD?.monthPick === true).map((post) =>{
                return(
                    <>
                    <WhiteCard desc={post?.node?.shortDescription?.shortText} image={post?.node?.articlesD?.bannerImage?.sourceUrl} title={post?.node?.title} link={`/blog/${post?.node?.slug}`}/>
                    </>
                )
            } )}
            </div>
        </div>
    );
}
export default WeekPicks;