import { Link } from "gatsby";
import React from "react";
import * as styles from "./MagazineNavigation.module.scss";
import {FaFacebookF,FaInstagram,FaLinkedinIn} from "react-icons/fa";
import {CiMail} from "react-icons/ci";

function MagazineNavigation(){
    const links = [
        {
            title:"Latest Articles",
            link:"#latest-articles"
        },
        {
            title:"Month Picks",
            link:"#month-picks"
        },
        {
            title:"Quotes",
            link:"#quotes"
        },
        {
            title:"Presentation Insights",
            link:"#presentation-insights"
        },
        {
            title:"Subscribe",
            link:"#subscribe"
        },
    ]

    const socials = [
        {
            icon:<FaFacebookF/>,
            link:"https://www.facebook.com/masterwizr"
        },
        {
            icon:<FaLinkedinIn/>,
            link:"https://no.linkedin.com/company/masterwizr"
        },
        {
            icon:<FaInstagram />,
            link:"https://www.instagram.com/masterwizr/?hl=en"
        },
        {
            icon:<CiMail />,
            link:""
        }
    ]
    return(
        <div className={styles.navigation}>
        <div className={styles.links}>
        <div className={styles.logo}>
         <h2> WiZR MAGAZINE</h2>
         </div>
        {links.map((item,i) =>{
            return(
                <Link  to={item.link}>
                    {item.title}
                </Link>
            );
         })}
        </div>
        <div className={styles.icons}>
            {socials.map((item,i) =>{
                return(
                    <a aria-label="redirect" href={item.link}  target={"_blank"} rel="noreferrer">
                    <div className={styles.icon}>
                        {/* {item.icon} */}
                        {item.icon}
                        </div>
                    </a>
                )
            })}
        </div>
        </div>
    )

}
export default MagazineNavigation;