import React from "react";
import QuoteCard from "../../atoms/QuoteCard/QuoteCard";
import * as styles from "./Quotes.module.scss";



function Quotes({data,title}){
    return(
        <div className={styles.quotes}>
           {title &&  <h1>{title}</h1>}
            <div className={styles.content}>
                {data?.map((d,i) =>{
                    return(
                        <QuoteCard {...d} key={i}/>
                    )
                })}
            </div>
        </div>
    );
}
export default Quotes;