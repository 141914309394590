import React from "react";
import * as styles from "./QuoteCard.module.scss";

function QuoteCard({quote,name}){
    return(
        <div className={styles.quotecard}>
          <h2>{quote}</h2>
          {name && <p>{name}</p>}
        </div>
    );
}
export default QuoteCard;