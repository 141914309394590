import MagazineImage from "../../molecules/MagazineImage/MagazineImage";
import * as styles from "./MagazineSections.module.scss";
import React from "react";
function MagazineSections({ data }) {
  return (
    <div id="latest-articles" className={styles.sections}>
      {data.edges
        .filter((post) => post?.node?.articlesD?.showItem === null)
        .slice(0, 3)
        .map((post) => {
          return (
            <>
              <MagazineImage
                image={post.node.articlesD.bannerImage?.sourceUrl}
                title={post.node.title}
                description={post?.node?.shortDescription?.shortText}
                link={`/blog/${post.node.slug}`}
              />
            </>
          );
        })}
    </div>
  );
}
export default MagazineSections;
