
import React, { useState} from "react";
import "../../../atoms/HomeSwiper/HomeSwiper.scss";
import Swiper from "react-swipeable-views";
import * as style from './style.module.scss';
import image1 from  "../../../../images/masterarticle.png";
import image2 from  "../../../../images/visualarticle.png"


import ArticlesSliderItem  from '../../../atoms/Magazine/articlesSliderItem';
import {BsArrowRight,BsArrowLeft} from 'react-icons/bs';

export default function ArticlesSlider() {
    const [index,setIndex] = useState(0);
    const increaseIndex = () => {
        index === 5 ? setIndex(0) : setIndex(index+1);
    }
    const decreaseIndex = () => {
        index === 0 ? setIndex(5) : setIndex(index-1);
    }

    const data = [
        {
            description:"Master of what?",
            date:"December 18, 2021",
            bgImage1: image1,
            description2:"Deep Visual Connection",
            date2:"December 21, 2021",
            bgImage2: image2,
            index:0,
            link : "/blog/master-of-what",
            link2: "/blog/deep-visual-connection"
        },
      
    ];

    return (
        <>
        <div id="master-concepts"  style={{paddingBottom:"0",marginBottom:"-10rem"}} className={style.wrapper}>
          <div className={style.mainWrapper}>
        <h1 className={style.title}>Master Concepts</h1>
            <Swiper index={index} enableMouseEvents >
                {data.map(item => (
                     <ArticlesSliderItem index={item.index} description={item.description} description2={item.description2} date={item.date} date2={item.date2} bgImage1={item.bgImage1} bgImage2={item.bgImage2} link={item.link} link2={item.link2}/>
                ))}
            </Swiper>
      
                      <div className={style.arrowsArticles} >
            <div
             
              onClick={() => decreaseIndex()}
              onKeyDown={() => decreaseIndex()}
            >
              <div >
              <BsArrowLeft style={{ fontSize:"3.5rem", color: "#D0D0D0" }}/>
              </div>
            </div>
            <div
             
              onClick={() => increaseIndex()}
              onKeyDown={() => increaseIndex()}
            >
              <div >
              <BsArrowRight style={{ fontSize:"3.5rem",marginLeft:"20px", color: "#D0D0D0" }}/>
              </div>
            </div>
          </div>
          </div>
          </div>
        </>
    );
}