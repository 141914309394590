import React from "react";
import * as styles from "./MagazineFirstSection.module.scss";
import image from '../../../images/logo-transparent.png'

function MagazineFirstSection(){
    return(
        <div className={styles.magazineBanner}>
           <div className={styles.imageTop}>
            <img src={image} alt="logo" />
           <h1>MAGAZINE</h1>
           </div>
        </div>
    );
}
export default MagazineFirstSection;