import React from "react";
import SmCard from "../../molecules/SmCard/SmCard";
import * as styles from "./LatestStories.module.scss";

function LatestStories({data}){
    return(
        <div id="presentation-insights" className={styles.container}>
            <div className={styles.bd} />
            <h1>Presentation Insights</h1>
            <div className={styles.data}>
            {data.edges.filter((post) => post?.node?.articlesD?.presentation===true && post?.node?.articlesD?.showItem===true ).slice(-6).map((post) =>{
                    return(
                        <SmCard tag={post?.node?.shortDescription?.articleCategory} image={post.node.articlesD.bannerImage?.sourceUrl} title={post.node.title} link={`/blog/${post.node.slug}`}/>
                    )
                })}
            </div>
                {/* <Subscribe /> */}
        </div>
    );
}
export default LatestStories;