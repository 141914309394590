import React from "react";
import * as styles from "./Modal.module.scss";

function Modal({handleclose}){
    return(
        <div className={styles.modal}>
            <div className={styles.frame}>
                <button className={styles.close}>x</button>
                <div className={styles.image} />
                <div className={styles.content}>
                <h3>SUBSCRIBE</h3>
                <h1>Never miss a post</h1>
                <ul>
                    <li style={{marginBottom:"15px"}}><b>The best deals and opportunities through our simple subscription with 1-2 updates monthly:</b></li>
                    <li>1. Join our News Letter and other updates</li>
                    <li>2. Get to try amazing software before everyone else</li>
                    <li>3. Subscribe to get our latest updates</li>

                </ul>
                <input type={"text"} placeholder="Enter your email address" />
                <button onClick={handleclose} className={styles.btn}>Subscribe</button>
                </div>
            </div>
        </div>
    );
}
export default Modal;