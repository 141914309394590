import { Link } from "gatsby";
import React from "react";
import * as styles from "./SmCard.module.scss";


function SmCard({title,tag,image,link}){
    return(
        <Link to={link}>
        <div className={styles.card}>
         <div>
         <div style={{backgroundImage:`url(${image})`}} className={styles.image}/>
         </div>
         <div className={styles.content}>
                  <p>{tag}</p>
                  <h2>{title}</h2>
                </div>
        </div>
        </Link>
    );
}
export default SmCard;