import React, { useState } from "react";
import * as styles from "./Subscribe.module.scss";
import axios from "axios";
import validator from "validator";
import image from "../../../images/logo-transparent.png";

function Subscribe() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (e) => {
    let email = e.target.value;
    setEmail(email);
    if (validator.isEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const handleSubmit = async () => {
    if (!emailError) {
      await axios({
        method: "post",
        url: "https://api.sendinblue.com/v3/contacts",
        data: {
          updateEnabled: false,
          email: email,
          listIds: [2],
          attributes: { tags: "Master WiZR" },
        },
        headers: {
          "Content-Type": "application/json",
          "api-key":
            "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",
        },
      })
        .then(function () {
          setSuccess(true);
        })
        .catch(function () {
          setEmailError("Something went wrong!");
        });
    }
  };
  return (
    <div className={styles.container}>
    <div id="subscribe" className={styles.Subscribe}>
      <div className={styles.content}>
        <h1>Subscribe to our newsletter</h1>
        <p>37% of remote employees say they have digital fatigue.</p>
        <div className={styles.inputContainer}>
          <div className={styles.inputGroup}>
            {!success && (
              <>
                <input
                  type={"email"}
                  placeholder="Enter your email address"
                  onChange={(e) => validateEmail(e)}
                />
                <button onClick={handleSubmit}>Subscribe</button>
              </>
            )}
          </div>
          {emailError && (
            <div className={styles.errorMessages}>
              <span
                style={{
                  color: "red",
                  fontWeight: 500,
                  fontSize: 15,
                }}
              >
                {emailError}
              </span>
            </div>
          )}
          {success && (
            <div className={styles.success}>
              <img src={image} width={120} alt="subscribe" />
              <h2>Thanks for subscribing</h2>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Subscribe;
