// extracted by mini-css-extract-plugin
export var arrowsArticles = "style-module--arrowsArticles--3c329";
export var arrowsWrapper = "style-module--arrowsWrapper--2ae3c";
export var arrowsliderwrapper = "style-module--arrowsliderwrapper--1c86b";
export var mainWrapper = "style-module--mainWrapper--39555";
export var next2 = "style-module--next2--73e57";
export var prev2 = "style-module--prev2--13c61";
export var swiperButtonNext = "style-module--swiper-button-next--2a672";
export var swiperButtonPrev = "style-module--swiper-button-prev--5cbe0";
export var title = "style-module--title--6b81b";
export var wrapper = "style-module--wrapper--5f2a5";